<!-- Version: 1.0 -->
<template>
  <div ref="datePicker">
    <div class="eh-input-box eh-datepicker-input-box">
      <input
        @focus="isFocus = true"
        :placeholder="placeholder ? placeholder : 'YYYY-MM-DD'"
        :disabled="disabled"
        v-model="tempValue"
        class="eh-base-input"
        :class="setClass"
      />
      <span
        @click="isShowCalendar = !isShowCalendar"
        class="eh-datepicker-icon"
      >
        <b-icon icon="calendar"></b-icon>
      </span>
      <div v-if="isShowCalendar && !disabled" class="eh-datepicker-calendar">
        <b-calendar
          v-model="tempValue"
          :locale="$i18n.locale"
          v-bind="labels[$i18n.locale] || {}"
          :start-weekday="1"
        ></b-calendar>
      </div>
      <span v-if="label" class="eh-base-input-floating-label">
        {{ label }}
        <span v-if="required && label" style="color: red">*</span>
      </span>
      <div class="eh-base-input-desc-error-box">
        <div v-if="description" class="eh-base-input-description">
          <div v-html="description"></div>
        </div>
        <div v-if="!state && error" class="eh-base-input-error-text">
          {{ error }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "fp-date-picker",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    label: String,
    disabled: Boolean,
    required: Boolean,
    placeholder: String,
    description: String,
    error: String,
    state: { type: Boolean, default: true },
    value: String,
    defaultValue: String,
  },
  data() {
    return {
      isFocus: false,
      isShowCalendar: false,
      tempValue: this.setDateValue(),
      labels: { [this.$i18n.locale]: this.$t("base.calendar.bootstrap") },
      isValid: this.state,
    };
  },
  watch: {
    tempValue(input) {
      if (input) {
        //var dateformat = /^[0-9]{4}(-([1-9]|0[1-9]|1[0-2]))?(-([1-9]|0[1-9]|[1-2][0-9]|3[0-1]))?/; //TODO: ha nem kell hogy 3 tagú legyen
        var dateformat = /^[0-9]{4}(-([1-9]|0[1-9]|1[0-2]))(-([1-9]|0[1-9]|[1-2][0-9]|3[0-1]))/;
        const validDate = input.match(dateformat) ? true : false;
        if (validDate && moment(input).isValid()) {
          this.$emit("change", input);
          this.isValid = true;
        } else {
          this.isValid = false;
        }
      } else {
        this.$emit("change", "");
        this.isValid = this.state;
      }
    },
    value() {
      this.tempValue = this.setDateValue();
    },
  },
  computed: {
    setClass() {
      var temp = "";
      if (this.label) {
        temp += " with-label ";
      } else {
        temp += " without-label ";
      }
      if (this.isValid) {
        temp += " valid-input ";
      } else {
        temp += " invalid-input ";
      }
      return temp;
    },
  },
  methods: {
    clickOutOfBoxEvent(e) {
      if (!this.$refs.datePicker.contains(e.target)) {
        this.isFocus = false;
        this.isShowCalendar = false;
      }
    },
    setDateValue() {
      const temp = this.value;
      if (temp && moment(temp).isValid() && temp.includes("T")) {
        this.$emit("change", moment(temp).format("YYYY-MM-DD"));
        return moment(temp).format("YYYY-MM-DD");
      } else if (temp && moment(temp).isValid()) {
        this.$emit("change", temp);
        return temp;
      }
      return "";
    },
  },
  mounted() {
    window.addEventListener("click", this.clickOutOfBoxEvent);
  },
  beforeDestroy() {
    window.removeEventListener("click", this.clickOutOfBoxEvent);
  },
};
</script>
